<template>
  <div>
    <v-container fluid>
      <v-row class="pd0">
        <v-col lg="7">
          <div class="inside-bg">&nbsp;</div>
        </v-col>
        <v-col lg="5">
          <div class="logo-img">
            <img
              class="img-fluid"
              lazy-src="../assets/logo.png"
              src="../assets/logo.png"
            />
          </div>
        </v-col>
      </v-row>
      <v-row fill-height class="mb30">
        <v-col>
          <v-card class="mx-auto p40 flexcard" max-width="1200">
            <v-card-text class="grow">
              <h3 class="headline" style="line-height: 3rem">
                1. SKYRIZI Rezumatul Caracteristicilor Produsului, Octombrie 20
                20.<br /><br />
                2. Gordon KB, Strober B, Lebwohl M, et al. Efficacy and safety
                of Risankizumab in moderate-to-severe plaque psoriasis
                (UltIMMa-1 and UltIMMa-2): results from two double blind,
                randomised, placebo-controlled and ustekinumab controlled phase
                3 trials. <br />Lancet. 2018;392(10148):650- 661.
                <br />doi:10.1016/S0140-6736(18):31713.
              </h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="fixed-bottom pd0">
        <v-col lg="12">
          <div class="bottom-bg">
            <div class="logo-img-bottom">
              <img
                class="img-fluid"
                lazy-src="../assets/abbvie-logo.png"
                src="../assets/abbvie-logo.png"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Referinte",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.inside-bg {
  background: url(../assets/top-bg-intern.png) no-repeat center;
  background-size: 100%;
  background-position: top left;
  max-height: 150px;
  height: 150px;
}
.fixed-bottom {
  height: 70px !important;
}
.bottom-bg {
  background: url(../assets/bottom-bg-end.png) no-repeat center;

  background-position: bottom right;
  max-height: 200px;
  height: 185px;
}
.logo-img {
  float: right;
  margin-right: 50px;
}
.logo-img-bottom {
  margin-left: 100px;
  padding-top: 105px;
}
.clear-both {
  clear: both;
}
.v-input--selection-controls .v-radio.v-item--active > .v-label {
  background: #af2a64 !important;
}
.v-input--selection-controls .v-radio.v-item--active.good-item > .v-label {
  background: #2896ce;
}
</style>
