<template>
  <div>
    <v-container fluid>
      <v-row class="pd0">
        <v-col lg="7">
          <div class="inside-bg">&nbsp;</div>
        </v-col>
        <v-col lg="5">
          <div class="logo-img">
            <img
              class="img-fluid"
              lazy-src="../assets/logo.png"
              src="../assets/logo.png"
            />
          </div>
        </v-col>
      </v-row>
      <v-row fill-height class="mb30">
        <v-col>
          <v-card class="mx-auto p40 flexcard">
            <v-card-text class="grow">
              <h3 class="headline text-center">
                Cărui curent artistic aparține capodopera “Fata cu cercel de
                perlă” a lui Johannes Vermeer?
              </h3>
              <v-form v-model="valid">
                <v-radio-group>
                  <v-row>
                    <v-col lg="3" md="3">
                      <v-radio
                        label="A. Expresionism"
                        value="a"
                        @change="onChange('a')"
                      >
                      </v-radio>
                      <v-radio
                        label="B. Renaștere"
                        value="b"
                        @change="onChange('b')"
                      >
                      </v-radio>
                    </v-col>
                    <v-col lg="6" md="6">
                      <div class="image-container">
                        <div
                          class="image-text"
                          v-bind:class="{
                            visible: withtext != false,
                          }"
                        >
                          <div class="image-inner-text">
                            <h3>{{ message }}</h3>
                            <p>
                              Frumusețea și simplitatea au fost redate în fel și
                              chip în artă. Acest tablou este un exemplu
                              reprezentativ. Pentru medicii dermatologi,
                              SKYRIZI® are rolul de a reda pacientilor
                              pacienților bucuria clarității pe piele, simplu si
                              convenabil<sup>1</sup>.
                            </p>
                            <a href="/quiz-7" class="link-btn"
                              >Următoarea întrebare
                            </a>
                          </div>
                        </div>
                        <img
                          class="inner-image img-fluid"
                          lazy-src="../assets/quiz-f-1.jpg"
                          src="../assets/quiz-f-1.jpg"
                        /></div
                    ></v-col>
                    <v-col lg="3" md="3">
                      <v-radio
                        label="C. Neoclasicism"
                        value="c"
                        @change="onChange('c')"
                      >
                      </v-radio>
                      <v-radio
                        label="D. Baroque"
                        value="d"
                        class="good-item"
                        v-bind:class="{ blink_bg: isActive }"
                        @change="onChange('d')"
                      >
                      </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="fixed-bottom pd0">
        <v-col lg="5">
          <div class="logo-img-bottom">
            <img
              class="img-fluid"
              lazy-src="../assets/abbvie-logo.png"
              src="../assets/abbvie-logo.png"
            />
          </div>
        </v-col>
        <v-col lg="7">
          <div class="bottom-bg">&nbsp;</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "QuizF",
  data() {
    return {
      valid: true,
      message: "Upsss! Raspuns incorect!",
      withtext: false,
      isActive: false,
    };
  },
  methods: {
    onChange(value) {
      var data = value;
      if (data != "d") {
        this.isActive = true;
        this.withtext = false;
      } else {
        this.message = "Felicitări! Ai găsit răspunsul corect!";
        this.withtext = true;
        this.isActive = false;
      }
    },
  },
};
</script>
<style scoped>
.inside-bg {
  background: url(../assets/top-bg-intern.png) no-repeat center;
  background-size: 100%;
  background-position: top left;
  max-height: 150px;
  height: 150px;
}
.bottom-bg {
  background: url(../assets/bottom-bg.png) no-repeat center;
  background-size: 100%;
  background-position: bottom right;
  max-height: 130px;
  height: 130px;
}
.logo-img {
  float: right;
  margin-right: 50px;
}
.logo-img-bottom {
  margin-left: 100px;
}
.clear-both {
  clear: both;
}
.v-input--selection-controls .v-radio.v-item--active > .v-label {
  background: #af2a64 !important;
}
.v-input--selection-controls .v-radio.v-item--active.good-item > .v-label {
  background: #2896ce;
}
</style>
